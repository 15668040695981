<template>
  <div class="Recommend rtl bg-blue-grey-10 q-pa-sm e-pb-130" v-scroll="onScroll" ref="scrollFun">
    <!-- movies -->
    <div class="row q-mt-md align-center" v-for="(item, index) of moveiData" @click="onClickMovie(item)">
      <div class="height_150 width_130 bg-main-color q-mx-md border-r-10 overflow-h">
        <q-img :src="item.image_url" class="height_150 width_130" />
      </div>
      <!-- 电影详细 -->
      <div class="Ukij">
        <div class="font_size18 text-white q-mb-sm text-ellipsis width_190">{{ item.name }}</div>
        <div class="text-grey-5 q-mb-sm">
          <span>تۈرى : </span>
          <span v-for="(i, index) of item.category">
            <span>{{ i.name }}</span>
            <span v-if="index !== item.category.length - 1"> / </span>
          </span>
        </div>
        <div class="text-grey-5 q-mb-sm">
          <span>تەۋەلىكى : </span>
          <span v-for="(i, index) of item.country">
            <span>{{ i.name }}</span>
            <span v-if="index !== item.country.length - 1"> / </span>
          </span>
        </div>
        <div class="text-grey-5 q-mb-sm">
          <span> روللاردا : </span>
          <span v-if="item.actorsName">
            <span v-for="(i, index) of item.actorsName">
              <span>{{ i.name }}</span>
              <span v-if="index !== item.actorsName.length - 1"> / </span>
            </span>
          </span>
          <span v-else> ~ </span>
        </div>
        <div class="text-grey-5">باھا نۇمۇر : {{ item.score }}</div>
      </div>
    </div>

    <!-- loading -->
    <div class="fixed z-index1 width_100vw Dflex justify-center" v-if="loading" style="top: 50%;">
      <q-spinner-cube color="cyan-7" size="5.5em" />
    </div>

    <!-- 没有内容提示 -->
    <div v-if="moveiData.length > 0" class="Ukij text-center text-grey-5 q-mt-xl">تۈگىدى ~</div>
  </div>

  <Tab></Tab>
</template>

<script>
import { debounce } from 'quasar'
import Tab from '@/components/tab/Tab.vue';
import { getHouse } from '../../request/api';
export default {
  name: 'Recommend',
  components: {
    Tab,
  },
  data() {
    return {
      moveiData: [],

      pageNo: 1,
      pageSize: 10,
      totalPage: null,
      loading: false,
      status: 1,
      countryId: -1,
      is_multiset: -1,
      recommend: 1,
      popular: -1,
      is_free: -1,
      play_year: -1,
      language_type: -1,
      categoryId: -1,
    }
  },
  watch: {
    pageNo (val) {
      this.pageNo = val
      this.loading = true
      this.getPost()
    }
  },
  created() {
    this.getPost();
  },
  methods: {
    // 获取内容
    getPost() {
      let pageNo = this.pageNo,
        pageSize = this.pageSize,
        status = this.status,
        countryId = this.countryId,
        is_multiset = this.is_multiset,
        recommend = this.recommend,
        popular = this.popular,
        is_free = this.is_free,
        play_year = this.play_year,
        language_type = this.language_type,
        categoryId = this.categoryId
      getHouse(pageNo, pageSize, status, countryId, is_multiset, recommend, popular, is_free, play_year, language_type, categoryId).then(res => {
        if (res.status == 200) {
          this.moveiData = this.moveiData.concat(res.data.data);
          this.totalPage = res.data.totalPage;
          this.loading = false;
        }
      }).catch(err => {
        console.log('err: ', err);
      })
    },

    // 电影点击
    onClickMovie(item) {
      this.$router.push({
        path: '/detailed',
        query: {
          id: item.id
        }
      })
    },

    // 分页
    onScroll: debounce(function (position) {
      let scrollTop = this.$refs.scrollFun.scrollTop;
      let clientHeight = this.$refs.scrollFun.clientHeight;
      let scrollHeight = this.$refs.scrollFun.scrollHeight;
      let height = 50;
      if (scrollTop + clientHeight >= scrollHeight - height) {
        if (this.pageNo === this.totalPage) {
          return false
        } else if (this.pageNo < this.totalPage) {
          this.pageNo = this.pageNo + 1
        }
      } else {
        return false
      }
      
    }, 200)
  }
}
</script>