<template>
  <div class="Member">
    <!-- 标题 -->
    <div class="rtl Ukij">
      <p class="text-center q-py-md text-white font_size20">ئالىي ئەزالىق سېتىۋېلىش</p>
    </div>

    <!-- member type -->
    <div class="rtl Ukij q-px-md">
      <div
        :class="['row justify-between items-center e-border-a-2 q-pa-sm border-r-8 q-mb-md', memberActive == index ? 'bg-amber-1 e-border-a-orange-2' : '']"
        v-for="(item, index) in memberType" :key="item.id" @click="onClickMemberType(item, index)">
        <div class="">
          <div :class="['font_size14', memberActive == index ? 'text-yellow-8' : 'text-grey-8']">{{item.name}}</div>
          <div class="q-mt-sm font_size12 text-grey-5">{{item.remark}}</div>
        </div>
        <div class="text-main-color">{{item.price}}￥</div>
      </div>
    </div>

    <!-- 付款按钮 -->
    <div class="q-px-md Ukij">
      <q-btn class="full-width text-grey-9" size="lg" color="amber-4" unelevated label="سېتىۋېلىش"
        @click="onClickPayMent" />
    </div>

    <!-- 会员特权 -->
    <div class="rtl Ukij bg-grey-2 q-mt-md" style="padding-bottom: 70px;">
      <!-- 特权 -->
      <div class="bg-white q-mb-md q-pa-md">
        <div class="text-center text-main-color">ئالىي ئەزالىق ھوقوقلىرى</div>
        <div class="q-mt-md row">
          <div class="column items-center justify-center width_30p q-mb-md memberList" v-for="item in memberConten"
            :key="item.id">
            <div class="width_40 q-mb-sm"><q-img :src="item.icon" class="full-withd height_40" /></div>
            <div class="font_size10 text-grey-6">{{item.name}}</div>
          </div>
        </div>
      </div>

      <!-- 介绍 -->
      <div class="bg-white q-pa-md">
        <div class="text-main-color text-center q-mb-md">ئالىي ئەزالىق چۈشەندۈرۈش</div>
        <div class="font_size14 text-grey-6">
          <p class="q-my-sm text-justify">ئالىي ئەزالىق سېتىلىشى مەۋھۇم مەھسۇلات بولۇپ، سېتىۋالغاندىن كىيىن پۇل
            قايتۇرۇشنى قوللىمايدۇ. جەزىملەشتۈرگەندىن كىيىن ھەق تاپشۇرۇڭ.</p>
          <p class="text-justify">ئالىي ئەزالىق سېتىۋېلىش ياكى كىنو كۆرۈش جەريانىدا مەسىلىگە يولىقسىڭىز <span
              class="text-main-color">irshat09</span> نۇمۇرنى ئۈندىدارغا قوشۇپ ئالاقىلشىڭ.</p>
        </div>
      </div>
    </div>

    <!-- 付款成功 -->
    <q-dialog v-model="paymentInfoDialog" persistent>
      <q-card style="width: 300px">
        <q-card-section>
          <div class="text-h6 Ukij">ئۇقتۇرۇش</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="Ukij text-center rtl text-amber-5">ئالىي ئەزالىق ئېچىش مۇۋاپىقيەتلىك بولدى ! </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn class="Ukij" flat label="تامام" color="primary" v-close-popup @click="confirmBTN" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>

  <!-- Tab -->
  <Tab></Tab>
</template>

<script>
import Tab from '../../components/tab/Tab.vue';
import { payment, getTargetPrices, updateUserAuth } from '../../request/api.js';
import { reqUrl } from '@/request/url.js';

export default {
  name: 'Member',
  components: {
    Tab
  },
  data () {
    return {
      paymentInfoDialog: false,
      memberType: [],
      memberActive: 1,
      memberConten: [
        { id: 1, name: 'يېڭى فىلىملەر', icon: `${reqUrl.url.icon_url}nawe.svg`},
        { id: 2, name: 'ئېلانسىز', icon: `${reqUrl.url.icon_url}noads.svg`},
        { id: 3, name: 'تىجەشلىك', icon: `${reqUrl.url.icon_url}qianbao.svg`},
        { id: 4, name: 'سۈزۈكلىكى يۈقۇرى', icon: `${reqUrl.url.icon_url}clarity.svg`},
        { id: 5, name: 'سۈرئەت تېز', icon: `${reqUrl.url.icon_url}fast.svg`},
        { id: 6, name: 'يېڭىلىنىش تېز', icon: `${reqUrl.url.icon_url}update.svg`},
      ],
      price: 500,
      bodyCon: '周会员',
      vip_days: 7,
      vipType: 1,
    }
  },
  methods: {
    // 获取价格
    getPost() {
      getTargetPrices().then(res => {
        if (res.status == 200) {
          this.memberType = res.data
        }
      }).catch(err => {
        console.log('err: ', err);
      })
    },

    // 会员类型
    onClickMemberType(item, index) {
      this.memberActive = index
      this.price = item.price * 100
      // this.price = item.price
      switch (item.id) {
        case 1:
          this.bodyCon = '年会员';
          this.vip_days = 365;
          this.vipType = 3;
          break;
        case 2:
          this.bodyCon = '月会员';
          this.vip_days = 30;
          this.vipType = 2;
          break;
        case 3:
          this.bodyCon = '周会员';
          this.vip_days = 7;
          this.vipType = 1;
          break;
        case 4:
          this.bodyCon = '日会员';
          this.vip_days = 1;
          this.vipType = 0;
          break;
        default:
          console.log("dee")
          break;
      }
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    // 支付
    onClickPayMent () {
      let vipDays = this.vip_days
      let vipType = this.vipType
      let data = { bodyCon: this.bodyCon, commodity_type: this.vipType, price: this.price }
      let _this = this;
      payment(data).then(res => {
          function onBridgeReady () {
            WeixinJSBridge.invoke(
              'getBrandWCPayRequest', {
              "appId": res.data.appId,     //公众号ID，由商户传入     
              "timeStamp": res.data.timeStamp,         //时间戳，自1970年以来的秒数     
              "nonceStr": res.data.nonceStr, //随机串     
              "package": res.data.package,
              "signType": res.data.signType,         //微信签名方式：     
              "paySign": res.data.paySign //微信签名 
            },
              function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  let userInfo = JSON.parse(localStorage.getItem("userInfo"))
                  let user_id = userInfo.id
                  // let user_id = 1
                  let vip_days = vipDays
                  let type = vipType
                  let data = { user_id, vip_days, type }
                  updateUserAuth(data).then(res => {
                    // alert(`支付成功，发起更新请求也成功了: ${res}`)
                    // alert('支付成功')
                    _this.openPaymentInfoDialog();
                  }).catch(err => {})
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
              });
          }
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
          } else {
            onBridgeReady();
          }
      }).catch( err => {
        console.log("err: ", err)
      })
    },

    // 打开完成付款对话框
    openPaymentInfoDialog () {
      this.paymentInfoDialog = true;
    },

    // 确认完成付款
    confirmBTN() {
      this.paymentInfoDialog = false;

      this.$router.push({ path: '/mine' })
    },
  },
  created () {
    this.scroll_top()
    this.getPost()
  }
}
</script>

<style scoped>
.memberList {
  margin-left: 5%;
}
.memberList:nth-child(3n) {
  margin-left: 0;
}
</style>
