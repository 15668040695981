<template>
  <div class="more bg-blue-grey-10 q-pt-sm">
    <!-- 筛选 scrol -->
    <!-- 筛选演员国籍 -->
    <div class="rtl q-px-sm q-mt-md scrol_items Ukij" ref="scrollBox">
      <div
        :class="['q-mr-sm e-p-x5-y10 border-r-8', scrolActive1 == item.id ? 'bg-main-color text-white' : 'bg-grey-4 text-grey-7']"
        v-for="item in countryList" :key="item.id" @click="onClickScrolList1(item)">
        <div class="text-ellipsis">{{ item.name}}</div>
      </div>
    </div>

    <!-- 筛选演员性别 -->
    <div class="rtl q-px-sm q-mt-md scrol_items Ukij" ref="scrollBox">
      <div
        :class="['q-mr-sm e-p-x5-y10 border-r-8', scrolActive2 == item.id ? 'bg-main-color text-white' : 'bg-grey-4 text-grey-7']"
        v-for="item in sexList" :key="item.id" @click="onClickScrolList2(item)">{{ item.name }}</div>
    </div>

    <!-- loading -->
    <div class="fixed z-index1 width_100vw Dflex justify-center" v-if="loading" style="top: 50%">
      <q-spinner-cube color="teal-5" size="5.5em" />
    </div>

    <!-- 演员 -->
    <div class="q-px-sm rtl q-mt-md row q-mb-xl" v-scroll="onScroll" ref="scrollFun">
      <!-- 骨架 -->
      <div v-if="!actorDate" class="rtl q-px-sm q-my-sm row full-width justify-between">
        <div v-for="item in 12" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="actorDate" v-for="(item, index) in actorDate" :key="index" transition="scale"
        class="example-item width_30p q-mb-md movie_box" @click="onClickMovie(item)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url" class="full-width full-height" />
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{ item.name }}</div>
      </q-intersection>
    </div>

    <!-- 保险空 -->
    <div class="text-center text-grey-3 Ukij" style="padding-bottom: 100px;">-- تۈگىدى --</div>

    <!-- 防止白底 -->
    <div class="bg-blue-grey-10 z-index-1 fixed height_500 e-b-0 full-width"></div>

    <!-- tab -->
    <Tab></Tab>
  </div>
</template>


<script>
import { ref } from 'vue';
import Tab from '../../components/tab/Tab.vue';
import { debounce } from 'quasar';
import { getCategory, getActors } from '../../request/api';

export default {
  name: 'sets',
  components: {
    Tab
  },
  data() {
    return {
      loading: false,
      isRequesting: false,
      actorDate: [],
      scrolActive1: -1,
      scrolActive2: -1,
      sexList: [
        { id: -1, name: 'بارلىق' },
        { id: 1, name: 'ئەر' },
        { id: 0, name: 'ئايال' },
      ],
      sex: -1,
      nationality: -1,
      countryList: [],
      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
    }
  },
  watch: {
    pageNo (val) {
      this.pageNo = val
      this.loading = true
      this.getPost()
    }
  },
  methods: {
    // 获取演员
    getPost () {
      if (this.isRequesting) return; // 如果已经在请求，直接返回
      this.isRequesting = true;
      let sex = this.sex;
      let nationality = this.nationality;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      getActors(pageNo, pageSize, sex, nationality).then(res => {
        if(res.status == 200) {
          this.actorDate = this.actorDate.concat(res.data.data);
          this.totalPage = res.data.totalPage;
          this.loading = false
          this.isRequesting = false
        }
      })
    },

    // 获取国籍
    getCountry () {
      getCategory().then(res => {
        if (res.status == 200) {
          this.countryList = res.data.country
          let all_1 = { id: -1, name: 'بارلىق' }
          this.countryList.unshift(all_1)
        }
      }).catch(err => {
        console.log('err: ', err);
      })
    },

    // 筛选国家
    onClickScrolList1(item) {
      this.pageNo = 1;
      this.actorDate = [];
      this.nationality = item.id;
      this.scrolActive1 = item.id;
      this.getPost()
    },

    // 筛选性别
    onClickScrolList2(item) {
      this.pageNo = 1;
      this.actorDate = [];
      this.sex = item.id;
      this.scrolActive2 = item.id;
      this.getPost()
    },

    // 往演员电影页面
    onClickMovie(item) {
      this.$router.push({ path: '/star', query: { postId: item.id }});
    },

    // 滚动
    onScroll: debounce(function (position) {
      let scrollTop = this.$refs.scrollFun.scrollTop;
      let clientHeight = this.$refs.scrollFun.clientHeight;
      let scrollHeight = this.$refs.scrollFun.scrollHeight;
      let height = 50;
      if (scrollTop + clientHeight >= scrollHeight - height) {
        if (this.pageNo === this.totalPage) {
          return false
        } else if (this.pageNo < this.totalPage) {
          this.pageNo = this.pageNo + 1
        }
      } else {
        return false
      }
      
    }, 200),

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },
 },
 created() {
   this.getPost();
   this.getCountry();
   this.scroll_top();
 },
}
</script>

<style scoped>
@import './../../assets/style/style.css';

.movie_box { margin-left: 5%; }

.movie_box:nth-child(3n) {
  margin-left: 0;
}
.example-item {height: 190px;}
.scrol_items {
  display: flex;
  overflow-x: scroll;
}
</style>