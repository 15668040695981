<template>
  <div class="star rtl bg-blue-grey-10">
    <!-- 相关信息 -->
    <div class="row relative">
      <q-img class="height_200 bgImage" :src="detailed.image_url" />
      <div class="absolute width_130 line-height-200 q-mr-xl"> <q-img :src="detailed.image_url" /> </div>
      <div class="text-white absolute Ukij q-mr-xl" style="right: 150px; top: 30px;">
        <div class="">ئىسمى : {{ detailed.name }}</div>
        <div class="q-my-sm">دۆلەت تەۋەلىگى : {{ detailed.nationality ? detailed.nationality.name : '' }}</div>
        <div class="ltr">تۇغۇلغان ۋاقتى : {{ detailed.birthdate }}</div>
      </div>
    </div>

    <!-- 相关电影 -->
    <div class="q-mt-xl q-px-sm q-my-sm row e-pb-230">
      <!-- 骨架 -->
      <!-- <div v-if="!movieList" class="rtl q-px-sm q-my-sm row full-width">
        <div v-for="item in 6" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div> -->

      <q-intersection v-if="movieList" v-for="(item, index) in movieList" :key="index" transition="scale"
        class="example-item width_30p q-mb-md box-list" @click="onClickMovie(item)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size12">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{ item.name }}</div>

      </q-intersection>
    </div>

    <!-- 保险 -->
    <div v-if="!movieList" class="height_full_vh Ukij text-center q-mt-xl text-white font_size20">مەزمۇن يوق
      ~
    </div>

    <Tab></Tab>
  </div>
</template>

<script>
import Tab from '../../components/tab/Tab.vue';
import { getByIdActor } from '@/request/api';
import { reqUrl } from '@/request/url.js';
export default {
  name: 'star',
  components: {
    Tab
  },
  data () {
    return {
      detailed: '',
      postId: null,
      movieList: [],
      reqUrlData: reqUrl,
    }
  },
  created () {
    this.getQueryParam();
    this.scroll_top();
  },
  methods: {
    // 获取数据
    getPost(){
      getByIdActor(this.postId).then(res => {
        if(res.status == 200){
          this.detailed = res.data[0];
          this.movieList = res.data[0].moviesList;
        }
      }).catch(err => {})
    },

    // 获取地址兰参数
    getQueryParam () {
      this.postId = this.$route.query.postId;
      this.getPost();
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    // 点击电影
    onClickMovie(item) {
      let data = {
        id: item.id
      }
      this.$router.push({ path: '/detailed', query: data })
    },
  }
}
</script>

<style scoped>
.bgImage {
  background-position: center;
  background-size: cover;
  filter: blur(20px);
}
.box-list {
  margin-left: 5%;
}

.box-list:nth-child(3n) {
  margin-left: 0;
}
</style>
