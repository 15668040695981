<style>
.q-notification {
  direction: rtl;
  font-family: "Ukij";
}
</style>

<template>
  <div class="NewMovie bg-blue-grey-10 e-pb-130 rtl q-pa-md">

    <!-- top tab -->
    <div class="row" style="border-bottom: 1px solid #01D5CB;">
      <div :style="item.id == 2 ? 'border-radius: 40px 0 0 0' : ''"
        :class="[activeTopTab == item.id ? 'text-white bg-main-color' : 'text-grey-13 topTabBtn']"
        class="Ukij q-py-sm q-px-lg" v-for="item of topTab" @click="onClickTopTab(item)">{{ item.name }}</div>
    </div>

    <!-- movies 待播放 -->
    <div v-for="(item, index) of awaitMovieData" v-if="activeTopTab == 1">
      <!-- 播放日期 -->
      <div class="text-white font_size20 q-mt-md">{{ item.playDate }} <span class="Ukij">قويىلىدۇ</span></div>
      <!-- 预告片 -->
      <div class="q-mt-sm relative" style="min-height: 200px;">
        <video :id="'my-player'+index" class="video-item" :src="item.videoUrl" controls preload="auto" ref="video"
          playsinline="true" webkit-playsinline="true" x5-playsinline="true" x5-video-player-type="h5"
          x5-video-player-fullscreen="true" x5-video-orientation="portraint" tabindex="2" mediatype="video" bindplay=""
          bindpause="" bindended="" bindtimeupdate="" bindwaiting="" binderror="">
        </video>

        <!-- 封面 -->
        <div class="absolute e-t-0 full-width" @click="onPlay(item, index)" v-if="currentItemIndex !== index">
          <q-img height="200px" :src="item.image_url" />
        </div>

        <div class="absolute" style="top: 30%; left: 45%" @click="onPlay(item, index)"
          v-if="currentItemIndex !== index">
          <q-img height="60px" width="60px" :src="playBtnUrl" />
        </div>

        <div class="absolute" style="top: 30%; left: 45%" v-if="currentItemIndex == index && playLoding">
          <q-spinner-ios :thickness="1" color="primary" size="4em" />
        </div>

      </div>

      <!-- 参数 -->
      <div class="row q-mt-sm">
        <q-img class="col-3" :src="item.image_url" />
        <div class="Ukij text-white q-mr-sm">
          <div class="font_size18">{{ item.name }}</div>
          <div class="font_size18">{{item.name_zh}}</div>
          <div class="">
            <span>ئىشلىگەن دۆلەت : </span>
            <span v-for="(i, index) of item.country ">
              <span>{{ i.name }}</span>
              <span v-if="index !== item.country.length - 1"> / </span>
            </span>
          </div>
          <div class="">
            <span>كىنو تۈرى : </span>
            <span v-for="(i, index) of item.category">
              <span>{{ i.name }}</span>
              <span v-if="index !== item.category.length - 1"> / </span>
            </span>
          </div>
          <div class="">روللاردا : ~</div>
        </div>
      </div>
      <!-- 详细 -->
      <div class="text-grey-5 Ukij q-my-md text-justify">{{ item.content }}</div>

      <!-- 分割线 -->
      <q-separator color="white" />
    </div>

    <!-- 最近播放影片 -->
    <div v-if="activeTopTab == 2">
      <div class="q-mt-lg row Ukij">
        <div @click="onClickFilterTime(1)" class="text-white q-px-md q-py-sm border-r-8 border-b1-main"
          :class="{ 'bg-main-color': filterTimeActive == 1 }">يېقىنقى 1 ئاي ئىچىدىكى</div>
        <div @click="onClickFilterTime(2)" class="text-white q-px-md q-py-sm q-mx-sm border-r-8 border-b1-main"
          :class="{ 'bg-main-color': filterTimeActive == 2 }">1 يىل ئىچىدىكى</div>
      </div>

      <div class="Dflex q-mt-md align-center" v-for="(item, index) of latelyMovieData" @click="onClickMovie(item)">
        <div class="height_150 width_130 bg-main-color q-mx-md border-r-10 overflow-h">
          <q-img :src="item.image_url" class="height_150 width_130" />
        </div>
        <!-- 电影详细 -->
        <div class="Ukij">
          <div class="font_size18 text-white q-mb-sm text-ellipsis width_190">{{ item.name }}</div>
          <div class="font_size18 text-white q-mb-sm text-ellipsis width_190">{{ item.name_zh }}</div>
          <div class="text-grey-5 q-mb-sm">
            <span>تۈرى : </span>
            <span v-for="(i, index) of item.category">
              <span>{{ i.name }}</span>
              <span v-if="index !== item.category.length - 1"> / </span>
            </span>
          </div>
          <div class="text-grey-5 q-mb-sm">
            <span>تەۋەلىكى : </span>
            <span v-for="(i, index) of item.country">
              <span>{{ i.name }}</span>
              <span v-if="index !== item.country.length - 1"> / </span>
            </span>
          </div>
          <div class="text-grey-5 q-mb-sm">قويۇلغان ۋاقتى : <span class="ltr" style="unicode-bidi: embed;">
              {{ item.playTime }}</span>
          </div>
        </div>
      </div>
    </div>


    <!-- 渲染完毕提示 -->
    <div class="Ukij text-white text-center" style="margin-top: 100px;">تۈگىدى ~</div>

    <!-- 保险空间 -->
    <!-- <div v-if="activeTopTab == 1 && awaitMovieData.length == 0" class="height_full_vh"></div>
    <div v-if="activeTopTab == 2 && latelyMovieData.length == 0" class="height_full_vh"></div> -->

  </div>

  <!-- Tab -->
  <Tab></Tab>
</template>

<script>
import { getNewMovie, getRecentlyMovive, getNewMovieAddress } from '../../request/api';
import Tab from '../../components/tab/Tab.vue';
export default {
  name: 'NewMovie',
  components: {
    Tab
  },
  data () {
    return {
      awaitMovieData: [],
      latelyMovieData: [],
      playBtnUrl: 'https://qiyat.oss-cn-beijing.aliyuncs.com/play.png',
      isPlayBtn: true,
      currentItemIndex: -1,
      baoxiaon: true,
      playLoding: false,
      topTab: [
        { id: 1, name: 'قويۇلىش ئالدىدىكى' },
        { id: 2, name: 'يېقىندا قويۇلغان' },
      ],
      playTime: 0,
      activeTopTab: 1,
      filterTimeActive: 1,
      newMoviePageNo: 1,
      newMoviePageSize: 10,
      recentlyPageNo: 1,
      recentlyPageSize: 10,
    }
  },
  watch: {},
  created () {
    this.scroll_top();
    this.getPost();
  },
  methods: {
    // 获取数据
    getPost(){
      getNewMovie(this.newMoviePageNo, this.newMoviePageSize).then(res => {
        if(res.status == 200) {
          this.awaitMovieData = res.data.data;
        }
      }).catch(err => {})

      // 获取最近一个月的时间戳
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      // 获得毫秒时间戳后除以1000得到秒时间戳
      this.playTime = Math.floor(date.getTime() / 1000);
    },

    // 获取最近影片
    getRecently() {
      let playTime = this.playTime;
      let pageNo = this.recentlyPageNo;
      let pageSize = this.recentlyPageSize;
      let data = { playTime, pageNo, pageSize };
      getRecentlyMovive(data).then(res => {
        if(res.status == 200) {
          for (let item of res.data.data) {
            var date = new Date(item.playTime * 1000)

            // 或者，使用特定的格式
            var formattedDate = date.getFullYear() + '-' +
              ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
              ('0' + date.getDate()).slice(-2) + ' ';

            item.playTime = formattedDate;
          }
          this.latelyMovieData = res.data.data;
        }
      }).catch(err => {})
    },

    // 播放视频
    async onPlay(item, index) {
      this.currentItemIndex = index;
      this.playLoding = true;

      // 添加视频地址
      await getNewMovieAddress({ url: item.url }).then(res => {
        if(res.status == 200) {
          for (let i of this.awaitMovieData) {
            if(i.id == item.id) {
              i.videoUrl = res.data;
            }
          }

          this.playLoding = false;
        }
      })
      
      // 获取所有视频元素
      let videoPlayers = document.querySelectorAll('video');

      // 遍历所有视频，暂停所有正在播放的视频
      videoPlayers.forEach((player, i) => {
        if (i !== index) { // 如果不是当前点击的视频
          player.pause(); // 暂停视频
        }
      });
      
      // this.currentItemIndex = index;
      let my_player = document.getElementById('my-player'+index);
      my_player.play();
    },

    // 顶部tab点击
    onClickTopTab(item) {
      this.currentItemIndex = -1;
      switch (item.id) {
        case 1:
          this.activeTopTab = 1;
          this.getPost();
          break;
        case 2:
          this.activeTopTab = 2;
          this.filterTimeActive = 1;
          this.getRecently();
          break;
        default:
          break;
      }
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    // 筛选时间
    onClickFilterTime(e) {
      this.filterTimeActive = e;
      let date = null;
      switch (e) {
        case 1:
          date = new Date();
          date.setMonth(date.getMonth() - 1);
          // 获得毫秒时间戳后除以1000得到秒时间戳
          this.playTime = Math.floor(date.getTime() / 1000);
          this.getRecently();
          break;
        case 2:
          date = new Date();
          date.setFullYear(date.getFullYear() - 1);
          this.playTime = Math.floor(date.getTime() / 1000);
          this.getRecently();
          break;
        default:
          break;
      }
    },

    // 点击电影
    onClickMovie(item) {
      let data = {
        id: item.id,
        type: item.country[0].id,
      }
      this.$router.push({ path: '/detailed', query: data })
    },
  },
  
}
</script>

<style scoped>
.video-item {
  width: 100%;
}

.topTabBtn {
  border-left: 1px solid #01D5CB;
  border-top: 1px solid #01D5CB;
  border-right: 1px solid #01D5CB;
}

.movie_box {
  margin-left: 5%;
}

.movie_box:nth-child(3n) {
  margin-left: 0;
}

.noVipMask {
  background-color: rgba(47, 47, 47, 0.9);
}
</style>
