import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import More from '../views/More/More.vue'
import Mine from '../views/Mine/Mine.vue'
import OriginalFilm from '../views/OriginalFilm/OriginalFilm.vue'
import Detailed from '../views/Detailed/Detailed.vue'
import Member from '../views/Member/Member.vue'
import Settings from '../views/Settings/Settings.vue'
import Refresh from '../components/Refresh/Refresh.vue'
import Favorites from '../views/Favorites/Favorites.vue'
import sets from '../views/sets/sets.vue'
import TVseries from '../views/TVseries/TVseries.vue'
import named from '../views/named/named.vue'
import broker from '../views/broker/broker.vue'
import NewMovie from '../views/NewMovie/NewMovie.vue'
import Popular from '../views/Popular/Popular.vue'
import Recommend from '../views/Recommend/Recommend.vue'
import Star from '../views/Star/Star.vue'
import Single from '../views/Single/Single.vue'
import Multiple from '../views/Multiple/Multiple.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/more',
    name: 'More',
    component: More
  },
  {
    path: '/mine',
    name: 'Mine',
    component: Mine
  },
  {
    path: '/originalFilm',
    name: 'OriginalFilm',
    component: OriginalFilm
  },
  {
    path: '/detailed',
    name: 'Detailed',
    component: Detailed
  },
  {
    path: '/member',
    name: 'Member',
    component: Member
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/refresh',
    name: 'Refresh',
    component: Refresh
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites
  },
  {
    path: '/sets',
    name: 'sets',
    component: sets
  },
  {
    path:'/TVseries',
    mane:'TVseries',
    component: TVseries
  },
  {
    path:'/named',
    mane:'named',
    component: named
  },
  {
    path:'/broker',
    mane:'broker',
    component: broker
  },
  {
    path:'/newmovie',
    mane:'NewMovie',
    component: NewMovie
  },
  {
    path:'/popular',
    mane:'Popular',
    component: Popular
  },
  {
    path:'/recommend',
    mane:'Recommend',
    component: Recommend
  },
  {
    path:'/star',
    mane:'Star',
    component: Star
  },
  {
    path:'/single',
    mane:'Single',
    component: Single
  },
  {
    path:'/multiple',
    mane:'Multiple',
    component: Multiple
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
