<template>
  <div class="mine blue-grey-10">
    <div>hello</div>

    <!-- tab -->
    <Tab tab_active="3"></Tab>

  </div>
</template>

<script>
import Tab from '../../components/tab/Tab.vue';

export default {
  name: 'OriginalFilm',
  components: {
    Tab
  },
  data () {
    return {
      
    }
  },
  methods: {

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

  },
  created () {
    
  }
}
</script>

<style scoped>
.top_head {
  border-radius: 8px 8px 0 0;
}

.top_bottom {
  border-radius: 0 0 8px 8px;
}

.title_right {
  width: 5px;
  border-radius: 50px;
}

</style>
