<template>
  <!-- 利润，提现 -->
  <div class="bg-grey-10 rtl Ukij e-pb-100">
    <div class="rtl row justify-around">
      <div v-for="(item, index) in broker" :key="index" transition="scale"
        class="box-list example-item q-mb-md width_30p">
        <div class="full-height bg-cyan-1 border-r-10 q-mt-md">
          <q-img class="q-mr-md q-mt-sm" :src="item.icon" width="40px" height="40px" />
          <span class="q-ml-md q-mt-sm text-orange-10 fontW-900 font_size14 float-left">{{item.name}}</span>
          <div v-if="index == 2" class="float-left q-ml-md">
            <q-btn color="grey-5" text-color="pink-9" label="نەقلەشتۈرۈش"
              style="width:60px; height:20px; border-radius: 40px;" />
          </div>
          <span class="text-grey-5 q-mr-md font_size16 block q-mt-md fontW-900">￥{{item.score}}</span>
        </div>
      </div>
    </div>
    <!-- 佳片 标题 -->
    <div class="q-px-sm q-mt-lg rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2">كىنو ھەمبەھىرلەش</div>
      </div>
      <div class="row" @click="onClickMore(2)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 窗口 -->
    <div class="rtl q-px-sm q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieDate" class="rtl q-px-sm q-my-sm row full-width">
        <div v-for="item in 6" class="example-item1 width_30p q-mb-md box-list2">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieDate" v-for="(item, index) in newMovie" :key="index" transition="scale"
        class="example-item1 width_30p q-mb-md box-list2" @click="onClickMovie(item,5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size12">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size12 text-ellipsis text-center">{{item.name}}</div>
      </q-intersection>
    </div>
    <!-- 技术支持 -->
    <div class="bg-grey-10 text-center text-grey-6">科雅特网络科技 提供技术@</div>
  </div>

  <!-- Tab -->
  <Tab></Tab>

</template>

<script>
import {reqIndex} from '../../request/api.js';
import { ref } from 'vue'
import Tab from '../../components/tab/Tab.vue';
import { reqUrl } from '@/request/url.js';

export default {
  name: 'broker',
  components: {
    Tab
  },
  data() {
    return {
      reqUrlData: reqUrl,
      broker:[
        { id: 0, name: 'ئۇمۇمى كىرىم', score: 0, icon: `${reqUrl.url.icon_url}balance.svg`},
        { id: 1, name: 'بۈگۈنكى كىرىم', score: 0, icon:`${reqUrl.url.icon_url}mybalance.svg`},
        { id: 2, name: 'پورتمالىم', score: 0, icon:`${reqUrl.url.icon_url}purse.svg`},
        { id: 3, name: 'نەقلەشتۈرگىنىم', score: 110, icon:`${reqUrl.url.icon_url}Withdrawals.svg`}
      ],
      newMovie:[],
      movieDate: ref(true), // 电影骨架
    }
  },
  methods: {

    // 点击更多
    onClickMore (type) {
      let data = {
        type: type
      }
      this.$router.push({ path: '/detailed', query: data })
    },

    // 点击电影
    onClickMovie (item, type) {
      let data = {
        type: type,
        id: item.id
      }
      this.$router.push({ path: '/detailed', query: data })
    },

   //首页请求
   async getIndex() {
      await reqIndex().then(res => {
        if(res.status == 200) {
          this.newMovie = res.data.house.data
          this.movieDate = false
        }
      }).catch(err => {
        console.log('err: ', err);
      })
    },
 },

 created() {
  this.getIndex()
 }
}

</script>

<style scoped>
@import './../../assets/style/style.css';
.example-item {width: 165px; height: 100px;}
.box-list:nth-child(2n) {
  margin-left: 0;
}
.box-list {margin-left: 5%;}
.example-item1 {height: 180px;}
.box-list2{margin-left: 5%;}
.box-list2:nth-child(3n) {
  margin-left: 0;
} 
.title_right {
  width: 5px;
  border-radius: 50px;
}
</style>