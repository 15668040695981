<template>
  <div class="favorites bg-blue-grey-10 e-pb-100 q-pt-md">
    <!-- loading -->
    <div class="fixed z-index1 width_100vw Dflex justify-center" v-if="loading" style="top: 50%">
      <q-spinner-cube color="teal-5" size="5.5em" />
    </div>

    <!-- movie -->
    <div class="q-px-sm rtl q-mt-md row q-mb-xl Ukij" v-scroll="onScroll" ref="scrollFun">
      <!-- 骨架 -->
      <div v-if="movieDate" class="rtl q-px-sm q-my-sm row full-width justify-between">
        <div v-for="item in 12" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieDate" v-for="(item, index) in movies" :key="index" transition="scale"
        class="example-item width_30p q-mb-md movie_box" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.movie.image_url ? item.movie.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.movie.is_free == 1"
            class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.movie.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size12">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.movie.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
        </div>
        <div class="Ukij font_size14 text-grey-2 text-center text-ellipsis">{{ item.movie.name }}</div>
      </q-intersection>
    </div>

    <!-- 保险空 -->
    <div class="q-my-md text-center text-grey-3 Ukij" v-if="movies.length != 0">تۈگىدى</div>
    <div class="q-my-md text-center text-grey-3 Ukij" v-if="movies.length == 0">مەزمۇن يوق</div>
    <div v-if="movies.length < 10" style="padding-bottom: 500px;"></div>

  </div>

  <!-- Tab -->
  <Tab></Tab>
</template>

<script>
import {reqGetFavorites} from '../../request/api';
import { debounce } from 'quasar';
import Tab from '../../components/tab/Tab.vue';
import { reqUrl } from '@/request/url.js';
export default {
  name: 'Favorites',
  components: {
    Tab
  },
  data() {
    return {
      type: 0,
      movies: [],
      scrolActive1: -1,
      scrolActive2: -1,
      pageNo: 1,
      totalPage: null,
      loading: false,
      movieDate: true, // 骨架
      reqUrlData: reqUrl,
    }
  },
  watch: {
    pageNo (val) {
      this.pageNo = val
      this.loading = true
      this.getPost()
    }
  },
  methods: {
    //获取内容
    getPost() {
      let type = parseInt(`${this.type}`, 10)
      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      // let user_id = 1
      let user_id = userInfo.id
      let pageNo = this.pageNo
      let pageSize = 10
      let data = {type, user_id, pageNo, pageSize}
      reqGetFavorites(data).then(res => {
        if(res.status == 200) {
          this.movies = this.movies.concat(res.data.arr)
          this.totalPage = res.data.totalPage
          this.loading = false
          this.movieDate = false
        }

      }).catch()
    },

    // 点击电影
    onClickMovie (item, type) {
      let data = {
        type: type,
        id: item.id
      }
      this.$router.push({ path: '/detailed', query: data })
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    onScroll: debounce(function (position) {
      let scrollTop = this.$refs.scrollFun.scrollTop;
      let clientHeight = this.$refs.scrollFun.clientHeight;
      let scrollHeight = this.$refs.scrollFun.scrollHeight;
      let height = 50;
      if(scrollTop +clientHeight >= scrollHeight - height){
        if(this.pageNo === this.totalPage){
          return false
        } else if (this.pageNo < this.totalPage) {
          this.pageNo = this.pageNo + 1
        }
      }else{
        return false
      }
      
    }, 200),

    // 获取地址兰参数
    getQueryParam() {
      this.type = this.$route.query.type
    },
  },
  created() {
    this.scroll_top()
    this.getQueryParam()
    this.getPost()
  }
}
</script>

<style scoped>
@import url(../../assets/style/style.css);
.q-btn-group {
  box-shadow: none;
}
.scrol_items {
  display: flex;
  overflow-x: scroll;
}
.movie_box {
  margin-left: 5%;
}
.movie_box:nth-child(3n){
  margin-left: 0;
}
.example-item {height: 180px;}
</style>
