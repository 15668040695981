import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'
import iconSet from 'quasar/icon-set/fontawesome-v6'

// 引入 Video.js
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

// Pinia
const pinia = createPinia();

// 使 Video.js 可在 Vue 实例中全局访问
const app = createApp(App);
app.config.globalProperties.$video = videojs;
app.mount('#app');

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).use(pinia).mount('#app')

createApp(App).use(Quasar, {
    iconSet: iconSet,
    plugins: {
        Notify
    },
    config: {
        notify: {
            position: "top"
        }
    }
})
