<template>
  <div class="row tab rtl full-width Ukij bg-blue-grey-10 zIndex1">
    <div class="row column inline justify-center items-center" v-for="(item, index) in tab_items" :key="index"
      @click="onClickTab(item)">
      <div><img :src="tab_active == index ? item.fill_icon : item.icon"></div>
      <div :class="tab_active == index ? 'text-main-color' : 'text-grey-6'">{{item.name}}</div>
    </div>
  </div>
</template>

<script>
import { reqUrl } from '../../request/url.js';
export default {
  name: 'Tab',
  props: {
    tab_active: {
      type: String,
      default: '-1',
    }
  },
  data() {
    return {
      tab_items: [
        { id: 0, name: 'باشبەت', icon: reqUrl.url.icon_url + 'hom.svg', fill_icon: reqUrl.url.icon_url + 'homfull.svg' },
        { id: 2, name: 'كىنو', icon: reqUrl.url.icon_url + 'leixing.svg', fill_icon: reqUrl.url.icon_url + 'leixingfull.svg' },
        { id: 1, name: 'قىسىملىق', icon: reqUrl.url.icon_url + 'tvseries.svg', fill_icon: reqUrl.url.icon_url + 'tvseriess.svg' },
        // { id: 3, name: '原片', icon: iconUrl + 'leixing.svg', fill_icon: iconUrl + 'leixingfull.svg' },
        { id: 3, name: 'مېنىڭ', icon: reqUrl.url.icon_url + 'my.svg', fill_icon: reqUrl.url.icon_url + 'myfull.svg' },
      ],
      // tab_active: 0
    }
  },
  emits: ['onClickTab'],
  methods: {
    // tab 点击
    onClickTab (item) {
      switch (item.id) {
        case 0:
          this.$router.push({
            path: "/"
          })
          break;
        case 1:
          this.$router.push({
            path: "/multiple"
          })
          break;
        case 2:
          this.$router.push({
            path: "/single"
          })
          break;
        // case 3:
        //   this.$router.push({
        //     path: "/originalFilm"
        //   })
        //   break;
        case 3:
          this.$router.push({
            path: "/mine"
          })
          break;
        default:
          break;
      }
      // this.tab_active = item.id;
    }
  }
}
</script>

<style scoped>
/* 公共样式 */
@import './../../assets/style/style.css';
.tab {
  height: 65px;
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0;
}
.tab>div {
  width: 25%;
}
.tab img {
  width: 25px;
}
</style>
