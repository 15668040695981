<template>
  <!-- 每日抽硬币 -->
  <div class="bg-grey-10 text-grey-2 rtl Ukij e-pb-100 border-r-10">
    <div class="row justify-center bg-grey-1 q-pa-md">
      <div class="q-ml-sm"><q-img :src="`${reqUrlData.url.vipAtushIconUrl}tuijian01.svg`" width="20px" height="20px" />
      </div>
      <div class="text-orange-9">ئاستىدىكى كۈن سانىنى بېسىپ يوقلىمىدىن ئۆتۈڭ !</div>
      <!-- 硬币 -->
      <div class="rtl q-px-sm row bg-grey-1 justify-between q-mt-md border-r-10">
        <div v-for="(item, index) in lottery" :key="index" transition="scale" class="box-list q-mb-md width-77"
          @click="onclicklottery(item)">
          <div
            :class="['height_100 border-r-10', active1 == item.id ? 'bg-amber-4 text-grey-2':'bg-grey-4 text-grey-6']">
            <div
              :class="['q-pt-sm fontW-900 font_size16 margin-right-38', active1 == item.id ?'text-grey-2':'text-grey-8']">
              {{item.name}}</div>
            <q-img class="margin-r-23 q-mt-md" :src="`${reqUrlData.url.icon_url}coins.svg`" width="25px"
              height="25px" />
            <div v-if="index == 6" class="float-left q-ml-md">
              <q-img class="margin-r-23" :src="`${reqUrlData.url.icon_url}great.svg`" width="60px" height="60px" />
            </div>
            <div class="q-mr-sm font_size14">{{item.coins}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 做任务收硬币 -->
    <div class="row justify-center q-mt-xl">
      <q-img class="q-ml-md" :src="`${reqUrlData.url.vipAtushIconUrl}tuijian01.svg`" width="25px" height="25px" />
      <span class="text-orange-13 font_size16">ۋەزىپە ئورۇنداپ تەڭگە يىغىڭ</span>
      <q-img class="q-mr-md" :src="`${reqUrlData.url.vipAtushIconUrl}tuijian01.svg`" width="25px" height="25px" />
    </div>
    <!-- 任务栏 -->
    <div class="rtl bg-grey-10 q-mt-sm">
      <div class="bg-grey-10 border-r-8 q-pa-md">
        <div class="row justify-between q-pb-md border-b-1 q-mb-md Ukij lists-style" v-for="item in taskbar"
          :key="item.id">
          <div class="text-blue-grey-1 row">
            <div class="width_30 q-ml-sm q-mt-sm"><q-img :src="item.icon" /></div>
            <div class="text-orange-2">{{item.name}} <div class="text-grey-5 ">{{item.introduction }}</div>
            </div>
          </div>
          <div class=""><q-btn color="deep-orange-5" style="border-radius: 40px;" :label="item.Send" /></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Tab -->
  <Tab></Tab>
</template>

<script>
import Tab from '../../components/tab/Tab.vue';
import { ref } from 'vue';
import { reqUrl } from '@/request/url.js';

export default {
  name: 'Named',
  components: {
    Tab
  },
  data() {
    return {
     userInfo: ref(null), // 用户信息
     active1 : -1,
     reqUrlData: reqUrl,
     lottery:[
        {id: 0, name:'1-كۈنى' , coins:'10 تەڭگە'},
        {id: 1, name:'2-كۈنى' , coins:'30 تەڭگە'},
        {id: 2, name:'3-كۈنى' , coins:'50 تەڭگە'},
        {id: 3, name:'4-كۈنى' , coins:'100 تەڭگە'},
        {id: 4, name:'5-كۈنى' , coins:'200 تەڭگە'},
        {id: 5, name:'6-كۈنى' , coins:'300 تەڭگە'},
        {id: 6, name:'7-كۈنى' , coins:'500 تەڭگە'},
     ],
     taskbar: [
       { id: 1, name: 'ئەپچاق ئاتلاپ تەڭگە يىغىڭ', introduction: 'باشقا ئەپچاققا ئاتلىسىڭىز قوشۇپبىرىلدۇ', icon: `${reqUrl.url.vipAtushIconUrl}wechat_forward.svg` , Send:'ئاتلاش'},
       { id: 2, name: 'كىنونى ساقلىۋېلىش ئارقىلىق تەڭگە يىغىڭ', introduction: 'ساقلاش مۇھاپىقىيەتلىك بولسا قوشۇپبىرىلدۇ', icon: `${reqUrl.url.icon_url}collection.svg` , Send:'ساقلاش'},
       { id: 3, name: 'توپقا يوللاپ تەڭگە يىغىڭ', introduction: 'دوستىڭىز كىرسە تەڭگە قوشۇپبىرىلدۇ', icon: `${reqUrl.url.icon_url}share.svg` , Send:'يوللاش'},
      ],
    }
  },
  methods: {
    // 抽硬币点击事件
    onclicklottery(item){
      this.active1 = item.id
    },
    //   // 登录请求
    // reqLoginFun() {
    //   reqLogin().then(res => {
    //     if (res.status == 200) {
    //       window.location.href = res.data
    //     }
    //   }).catch()
    // },

    // // 判断是否有 code
    // isCode() {
    //   if (window.location.search.includes('code=')) {
    //     localStorage.setItem('isCode', this.$route.query.code)
    //     let code = this.$route.query.code
    //     let data = { code }
    //     getuserInfo(data).then(res => {
    //       const userInfo = res.data.data
    //       const token = res.data.token
    //       this.userInfo = userInfo
    //       localStorage.setItem("userInfo", JSON.stringify(userInfo))
    //       localStorage.setItem("token", token)
    //     }).catch()
    //   }
    //   return localStorage.getItem("isCode") !== null
    // },

    // 获取用户信息
    // getLocalUserInfo () {
    //   this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    // },
  },
  created() {
//     if (!this.isCode()) this.reqLoginFun()
    // this.getLocalUserInfo()
  }
 }


</script>

<style scoped>
@import './../../assets/style/style.css';

.width_80p{width: 80%;}
.example-item {height: 100px;}
.box-list:nth-child(7n) {
  width: 175px;
}
.border-r-8{border-radius: 0 0 8px 8px;}
.width-77{width: 77px;}
.margin-r-23{margin-right: 23px;}
.margin-right-38{margin-right:16px}
</style>