<template>
  <div class="settings height_full_vh rtl Ukij bg-grey-2 q-py-md q-px-sm">
    <!-- 列表 list -->
    <div class="bg-white q-pa-md border-r-8 text-grey-7">
      <div class="row justify-between items-center q-mb-sm border-b-1 q-py-md">
        <div>
          <span>ئەزا نامى: </span>
          <span>{{userInfo.nickname}}</span>
        </div>
        <div class="height_30 width_30">
          <q-img class="full-height border-r-50p" :src="userInfo.headimgurl" />
        </div>
      </div>
      <div class="row justify-between items-center q-mb-sm border-b-1 q-py-md">
        <div>ئەزا نۇمۇرى</div>
        <div>uid: {{ userInfo.id }}</div>
      </div>
      <div class="row justify-between items-center">
        <div>يانفۇن نۇمۇرى</div>
        <div></div>
      </div>
    </div>

    <!-- 退出按钮 -->
    <div class="q-mt-md">
      <q-btn class="full-width q-py-md" color="secondary" label="ئاكونتتىن چىكىنىش" @click="onClickQuit" />
    </div>
  </div>

  <!-- Tab -->
  <Tab></Tab>
</template>

<script>
import Tab from '../../components/tab/Tab.vue';

export default {
  name: 'settings',
  components: {
    Tab
  },
  data () {
    return {
      userInfo: ''
    }
  },
  methods: {
    // get userInfo
    getUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    },

    // quit
    onClickQuit() {
      localStorage.clear()
      this.$router.push({ path: '/' })
    }
  },
  created () {
    this.getUserInfo()
  }
}
</script>

<style scoped>

</style>
