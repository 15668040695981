
import 'quasar/dist/quasar.css'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'


// To be used on app.use(Quasar, { ... })
export default {
  config: {
    notify: {
      position: 'top'
    }
  },
  plugins: [
    'notify'
  ],
  iconSet: iconSet
}