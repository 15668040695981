import { defineStore } from 'pinia';

// 首页数据
export const useHomeStore = defineStore('home', {
  state: () => { // data
    return {
      swiperList: [],
      newMovie: [],
      cartoon: [],
      actionMovie: [],
      recommendList: [],
      popular: [],
      drama: [],
      noticeArr: [],
      allMovie: [],
      movieDate: true,
      allMovieGuJia: true,
      swiperListGujia: true,
    }
  },
  getters: { // computed 计算属性
  },
  actions: { // methods
    add() {
      // this.num++;
    }
  }
});

// 首页各类轮播图
export const useHomeCategorySwiperStore = defineStore('homeCategorySwiper', {
  state: () => { // data
    return {
      swiperHomeData: [],
    }
  }
});

// 首页演员
export const useHomeActorStore = defineStore('homeActor', {
  state: () => { // data
    return {
      actorDate: [],
    }
  }
});

// 电影页面数据
export const useSingleStore = defineStore('single', {
  state: () => {
    return {
      swiperList: [],
      newMovie: [],
      action: [],
      fantaziye: [],
      drama: [],
      horror: [],
      love: [],
      movieLoadig: true,
      swiperListGujia: true,
    }
  }
});

// 电影页面数据
export const useMultipleStore = defineStore('multiple', {
  state: () => {
    return {
      swiperList: [],
      newMovie: [],
      china: [],
      india: [],
      korea: [],
      usa: [],
      other: [],
      movieLoadig: true,
      swiperListGujia: true,
    }
  }
});
