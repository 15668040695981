<template>
  <div class="Refresh">

  </div>
</template>

<script>
export default {
  name: 'Refresh',
  data () {
    return {
 
    }
  },
  methods: {},
  created () {
    this.$router.go(-1)
  }
}
</script>

