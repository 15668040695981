<template>
  <div class="popular bg-blue-grey-10 rtl q-pa-sm e-pb-130" v-scroll="onScroll" ref="scrollFun">
    <!-- top tab -->
    <div class="row border-b-main q-mt-sm border-r-8 overflow-h">
      <div v-for="item of topTab" class="Ukij text-main-color width_33p text-center q-py-sm font_size16"
        :class="topTabActive == item.id ? 'bg-main-color text-white-color' : ''" @click="ocTopTab(item)">
        {{item.name}}</div>
    </div>

    <!-- movies -->
    <div class="row q-mt-md align-center" v-for="(item, index) of moveiData" @click="onClickMovie(item)">
      <div class="index text-white">{{ index +1}}</div>
      <div class="height_150 width_130 bg-main-color q-mx-md border-r-10 overflow-h">
        <q-img :src="item.image_url" class="height_150 width_130" />
      </div>
      <!-- 电影详细 -->
      <div class="Ukij">
        <div class="font_size18 text-white q-mb-sm text-ellipsis width_160">{{ item.name }}</div>
        <div class="text-grey-5 q-mb-sm">
          <span>تۈرى : </span>
          <span v-for="(i, index) of item.category">
            <span>{{ i.name }}</span>
            <span v-if="index !== item.category.length - 1"> / </span>
          </span>
        </div>
        <div class="text-grey-5 q-mb-sm">
          <span>تەۋەلىكى : </span>
          <span v-for="(i, index) of item.country">
            <span>{{ i.name }}</span>
            <span v-if="index !== item.country.length - 1"> / </span>
          </span>
        </div>
        <div class="text-grey-5 q-mb-sm">
          <span> روللاردا : </span>
          <span v-if="item.actorsName">
            <span v-for="(i, index) of item.actorsName">
              <span>{{ i.name }}</span>
              <span v-if="index !== item.actorsName.length - 1"> / </span>
            </span>
          </span>
          <span v-else> ~ </span>
        </div>
        <div class="text-grey-5">باھا نۇمۇر : {{ item.score }}</div>
      </div>
    </div>

    <div v-if="moveiData.length == 0">
      <h4 class="Ukij text-grey-5 text-center q-mt-lg" style="padding-bottom: 400px; margin-top: 100px;">مەزمۇن يوق ~
      </h4>
    </div>

    <!-- 没有内容提示 -->
    <div v-if="moveiData.length > 0" class="Ukij text-center text-grey-5 q-mt-xl">تۈگىدى ~</div>

  </div>

  <Tab></Tab>
</template>

<script>
import Tab from '../../components/tab/Tab.vue';
import { getHouse } from '../../request/api';
import { debounce } from 'quasar';
export default {
  name: 'Popular',
  components: {
    Tab
  },
  data () {
    return {
      topTabActive: 0,
      topTab: [
        { id: 0, name: 'كىنولار' },
        { id: 1, name: 'قىسىملىق' },
        { id: 2, name: 'پىرگىرامما' },
      ],
      moveiData: [],

      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
      status: 1,
      countryId: -1,
      is_multiset: 0,
      recommend: -1,
      popular: 1,
      is_free: -1,
      play_year: -1,
      language_type: -1,
      categoryId: -1,
    }
  },
  created () {
    this.getPost();
  },
  methods: {
    // 获取数据
    getPost(){
      let pageNo = this.pageNo,
          pageSize = this.pageSize,
          status = this.status,
          countryId = this.countryId,
          is_multiset = this.is_multiset,
          recommend = this.recommend,
          popular = this.popular,
          is_free = this.is_free,
          play_year = this.play_year,
          language_type = this.language_type,
          categoryId = this.categoryId
      getHouse(pageNo, pageSize, status, countryId, is_multiset, recommend, popular, is_free, play_year, language_type, categoryId).then(res => {
        if(res.status == 200) {
          this.moveiData = res.data.data;
          this.totalPage = res.data.totalPage;
        }
      }).catch(err => {
        console.log('err: ', err);
      })
    },

    // top tab 点击
    ocTopTab(item) {
      this.topTabActive = item.id;
      this.is_multiset = item.id;
      this.getPost();
    },

    // 点击播放
    onClickMovie(item) {
      this.$router.push({
        path: "/detailed",
        query: {
          id: item.id
        }
      })
    },

    // 分页
    onScroll: debounce(function (position) {
      let scrollTop = this.$refs.scrollFun.scrollTop;
      let clientHeight = this.$refs.scrollFun.clientHeight;
      let scrollHeight = this.$refs.scrollFun.scrollHeight;
      let height = 50;
      if (scrollTop + clientHeight >= scrollHeight - height) {
        if (this.pageNo === this.totalPage) {
          return false
        } else if (this.pageNo < this.totalPage) {
          this.pageNo = this.pageNo + 1
        }
      } else {
        return false
      }

    }, 200)

  }
}
</script>

<style scoped>
.width_33p { width: 33.333%; }
.index {
  width: 35px;
  height: 35px;
  background-color: #01D5CB;
  position: relative;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>